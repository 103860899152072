import React from "react";
const Features = () => {
  return (
    <>
      <div className="game p60 pb-0">
        <div className="container">
          <h2 className="text-center mb-4 hadding">
            Our Great <span>Features</span>
          </h2>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img
                  src="/assets/img/reason1.png"
                  alt="Experienced Team & Multi-Collateral"
                />
                <h4>Experienced Team & Multi-Collateral</h4>
                <p className="mb-0">
                  Led by blockchain and finance experts, FSADUSD is backed by
                  diverse collateral, enhancing stability and reducing risk.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/dao.png" alt="DAO Governance" />
                <h4>DAO Governance</h4>
                <p className="mb-0">
                  Governed by a Decentralized Autonomous Organization, FSADUSD
                  empowers the community with decision-making through
                  token-based voting.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/redeemable.png" alt="1:1 Redeemable" />
                <h4>1:1 Redeemable</h4>
                <p className="mb-0">
                  Each FSADUSD token is redeemable 1:1 with its collateral,
                  ensuring reliable value.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/stability.png" alt="Stability" />
                <h4>Stability</h4>
                <p className="mb-0">
                  Advanced algorithms and diversified collateral ensure FSADUSD
                  maintains price stability, making it a dependable digital
                  asset.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/opportunities.png" alt="Opportunities" />
                <h4>Opportunities</h4>
                <p className="mb-0">
                  FSADUSD offers financial opportunities like earning interest
                  and yield farming, enhancing asset value and utility.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img
                  src="/assets/img/security_and_privacy.png"
                  alt="Security and Privacy"
                />
                <h4>Security and Privacy</h4>
                <p className="mb-0">
                  Top-tier security measures protect assets and data, with
                  advanced encryption ensuring transaction confidentiality.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/satisfaction.png" alt="Satisfaction" />
                <h4>Satisfaction</h4>
                <p className="mb-0">
                  We prioritize user satisfaction with excellent customer
                  support and continuous service improvements based on feedback.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="choose-item tf-step">
                <img src="/assets/img/transparency.png" alt="Transparency" />
                <h4>Transparency</h4>
                <p className="mb-0">
                  Regular audits and real-time updates on collateral reserves
                  ensure operational transparency and trust.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
