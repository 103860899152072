import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSetUserLoginMutation } from "../../../redux/loginApi";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import { useGetUserWalletBalanceMutation } from "../../../redux/userApi";

function ConnectWalletModal(props) {
  const { login,setWalletBalance } = useAuth();
  const [getUserWalletBalance]=useGetUserWalletBalanceMutation()
  const [setUserLogin] = useSetUserLoginMutation();
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [selectWalletId, setSelectWalletId] = useState("");
  const [selectWalletAddress, setSelectWalletAddress] = useState("");
  const [token, setToken] = useState("");
  const [inputs, setInputs] = useState(Array(12).fill(""));
  const inputChangedHandler = (e, index) => {
    const inputsUpdated = inputs.map((input, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return input;
      }
    });
    setInputs(inputsUpdated);
  };

  const handleClose = () => {
    props.setShow(false);
    setInputs(Array(12).fill(""));
    setWalletAddresses([])
    setSelectWalletId("")
    setSelectWalletAddress("")
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      twelve_keys: JSON.stringify(inputs),
    };
    setUserLogin(data).then((result) => {
      if (result.data.status) {
        setWalletAddresses(result.data.data);
        setToken(result.data.token);
        if (result.data.data.length > 0) {
          setSelectWalletId(result.data.data[0].id);
          setSelectWalletAddress(result.data.data[0].wallet_address);
        }
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const handleWallet = (e) => {
    setSelectWalletId(e.target.value);
    const filterWallet = walletAddresses?.filter((list) => {
      return list.id == e.target.value;
    });
    console.log(filterWallet,"filterWallet",e.target.value)
    setSelectWalletAddress(filterWallet[0].wallet_address);
  };

  const handleLogin = () => {
    login(selectWalletId, selectWalletAddress);
    localStorage.setItem("jwtToken", token);
    localStorage.setItem("walletId", selectWalletId);
    localStorage.setItem("walletAddress", selectWalletAddress);
    getUserWalletBalance({wallet_id:selectWalletId}).then((res)=>{
         setWalletBalance(res.data[0].balance)
    })
    handleClose()
  };

  return (
    <div>
      {" "}
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {walletAddresses.length==0?<><h3>Enter Secret Key</h3>
          <div className="d-flex row">
            {inputs.map((input, i) => (
               <div className="col-md-3">

              <span>
                <span>{i + 1}</span>
                <input
                  onChange={(e) => inputChangedHandler(e, i)}
                  value={input}
                  className="form-control"
                />
              </span>
              </div>
            ))}
          </div></>:<><h3>Please Select Wallet Address</h3>
          <div className="">
            {walletAddresses?.map((list)=>{
                return (
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={list.wallet_address}
                      label={list.wallet_address}
                      checked={selectWalletId == list.id ? true : false}
                      name="walletAddress"
                      onChange={handleWallet}
                      value={list.id}
                    />
                  </Form.Group>
                )
            })}
          </div></>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {walletAddresses.length > 0 ? (
            <Button variant="secondary" onClick={handleLogin}>
              Submit
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConnectWalletModal;
