//===================== Login ================
export const userLoginApi="/user-login"

//==================== Category ===============
export const getCategoryApi="/get-categories"

//================== product =================
export const getProductByCategoryApi="/get-product-by-category"

//================ Contact Category ==========
export const getContactCategoryApi="/get-contact-categories"

//================= Contact =============
export const addContactApi="/add-contact"

//=================== Balance ===============
export const getWalletBalance="/get-user-wallet-balance"

//================= Currency ================
export const getFsadUsdPrice="/get-fsad-usd-price"

//=================== Payment ================
export const getPaymentCountryApi="/get-payment-country"
export const getCurrencyPriceApi="/get-currency-price"
export const getPaymentMethod="/get-payment-method"
export const addPayemntRequest="/add-payment-request"
export const getCryptoCurrencyApi="/get-crypto-currency"
export const getCryptoCurrencyPriceApi="/get-crypto-currency-price"

//====================== Order =======================
export const getUserOrderApi="/get-user-order"

//================== Members =============
export const getMembersApi="/get-members"