import { getFsadUsdPrice } from "../Components/constant/api";
import { myApi } from "./api";

export const currencyApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrency: builder.query({
      query: () => ({
        url: getFsadUsdPrice,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetCurrencyQuery} = currencyApi;