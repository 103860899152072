import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const aboutUsRef = useRef(null);
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#home":
        scrollToRef(aboutUsRef);
        break;

      default:
        break;
    }
  }, [location.hash]);
  return (
    <header
      className="home"
      id="home"
      useRef={aboutUsRef}
      data-scroll-index="1"
    >
      <div className="banner_content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mt-md-5">
              <h3 className="mb-3">
                A New Era of Economic Prosperity With <span>FSAD369</span>
              </h3>
              <p>
                In a world where volatility has become synonymous with digital
                currencies, the FSADUSD Stablecoin emerges as a bastion of
                stability and reliability. This groundbreaking digital currency
                is backed by historical assets, ensuring that it maintains a
                stable 1:1 redemption value against the US dollar. The idea is
                simple yet revolutionary—create a digital currency that combines
                the borderless nature of cryptocurrencies with the stability and
                trust associated with traditional fiat currencies, and the
                security everyone is looking for.
              </p>
              <a
                className="btn btn-action mt-3 pl-4 pr-4"
                href="#section-6"
                data-scroll-nav="6"
              >
                Buy Now
              </a>
            </div>
            <div className="col-md-6 text-center">
              <div className="coinpool-animation start-animation">
                <div className="platinum-move-1"></div>
                <div className="platinum-move-2"></div>
                <div className="platinum-move-3"></div>
                <div className="platinum-move-4"></div>
                <div className="platinum-move-5"></div>
              </div>
              <div className="washer banner-element-ten">
                <img
                  className="imagerotate2 img-fluid"
                  src="/assets/img/hero-cryptoce-2.png"
                  alt=""
                />
                <img
                  className="imagerotate3 img-fluid"
                  src="/assets/img/hero-cryptoce-3.png"
                  alt=""
                />
                <img
                  className="imagerotate7 img-fluid"
                  src="/assets/img/section3.png"
                  alt=""
                />
                <img
                  className="imagerotate8 img-fluid"
                  src="/assets/img/section3.png"
                  alt=""
                />
                <img
                  src="/assets/img/coin.png"
                  alt="Launchpad"
                  className="img-fluid about2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
