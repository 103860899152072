import { addContactApi, getContactCategoryApi } from "../Components/constant/api";
import { myApi } from "./api";

export const contactApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactCategory: builder.query({
      query: () => ({
        url: getContactCategoryApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    setContact: builder.mutation({
      query: (post) => ({
        url: addContactApi,
        method: "POST",
        body:post
      }),
    }),
    
  }),
});

export const {useGetContactCategoryQuery,useSetContactMutation} = contactApi;