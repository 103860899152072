
import { createContext, useContext, useState,useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("jwtToken")?true:false);
  const [walletAddressId, setWalletAddressId] = useState(localStorage.getItem("walletId"));
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem("walletAddress"));
  const [walletBalance, setWalletBalance] = useState(0)
  const [usdPrice, setUsdPrice] = useState(1)

 useEffect(() => {
  if(localStorage.getItem("jwtToken")){
    setAuthenticated(true);
   
  }
 }, [authenticated]) 

  const login = (walletId,walletAddress) => {
    // Implement your authentication logic here
    setAuthenticated(true);
    setWalletAddressId(walletId)
    setWalletAddress(walletAddress)
    
  };

  const logout = () => {
    // Implement your logout logic here
    setAuthenticated(false);
    setWalletAddressId("")
    setWalletAddress("")
    setWalletBalance(0)
    localStorage.clear()
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout,walletAddressId,walletAddress,setWalletBalance,walletBalance,setUsdPrice,usdPrice }}>
      {children}
    </AuthContext.Provider>
  );
};