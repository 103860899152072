import { Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingCart from "./Components/pages/Cart/Cart";
import Homepages from "./Components/pages/Homepage";
import Navbar from "./Components/widgets/Navbar";
import DisclaimerPage from "./Components/pages/Disclaimer";
import HistoricalAssets from "./Components/pages/Histoicalasset";
import Assetslibrary from "./Components/pages/AseetLab/Assetslibrary";
import Profile from "./Components/pages/profile/Profile";
import Members from "./Components/pages/Members/Members";

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
      <Route path="/" element={<Homepages />} />
      <Route path="/#home" element={<Homepages />} />
      <Route path="/#about" element={<Homepages />} />
      <Route path="/#Tokenomics" element={<Homepages />} />
      <Route path="/#Roadmap" element={<Homepages/>} />
      <Route path="/#Contact" element={<Homepages />} />
      <Route path="/assets-library/:category_id" element={<Assetslibrary/>} />
      <Route path="/assets-library" element={<Assetslibrary/>} />
      <Route path="/cart" element={<ShoppingCart />} />
      <Route path="/disclimer" element={<DisclaimerPage />} />
      <Route path="/historical-assets" element={<HistoricalAssets/>}/>
      <Route path="/profile" element={<Profile/>}/>
      <Route path="/members" element={<Members/>}/>
    </Routes>
    <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
