import React,{useEffect,useState} from "react";
import AboutUs from "../widgets/Aboutus";
import ContactUs from "../widgets/ContactUs";
import Counter from "../widgets/Counter";
import Display from "../widgets/Display";
import Features from "../widgets/Features";
import Footer from "../widgets/Footer";
import Header from "../widgets/Header";
import KnowledgesLecture from "../widgets/KnowledgesLecture";
import Mission from "../widgets/Mission";
import Partner from "../widgets/Partner";
import RoadMap from "../widgets/RoadMap";
import SpotlightCurrency from "../widgets/SpotlightCurrency";
import Teamup from "../widgets/Teamup";
import io from "socket.io-client";
import { socketUrl } from "../constant/BaseUrl";
const Homepages = () => {
  const [number, setNumber] = useState(0);
  const [socket, setSocket] = useState()
  // useEffect(() => {
  //   // Example: Increment the counter every second
  //   const interval = setInterval(() => {
  //     setNumber(prevNumber => (prevNumber + 1) % 10000); // Reset to 0 after 9999
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);
  useEffect(() => {
    handleShow()
  }, [])
  
  const handleShow = (id) => {
    const socket = io.connect(socketUrl, {
      transports: ["polling"],
    });
    setSocket(socket)
    socket.emit("add-counter");
    socket.on("recieve-counter", (data) => {
      setNumber(data);
    });
  };

  useEffect(() => {
    socket?.on("recieve-counter", (data) => {
      console.log(data,"data")
      setNumber(data);
    });
  }, [socket])
  
  
  return (
    <>
      <Header />
      <AboutUs />
      <Features />
      <Mission />
      <Display />
      <Teamup />
      <SpotlightCurrency />
      <KnowledgesLecture/>
      <RoadMap />
      <Partner />
      <ContactUs />
      <Counter number={number}/>
      <Footer />
    </>
  );
};

export default Homepages;
