import React,{useState,useEffect} from "react";
import Navbar from "../../widgets/Navbar";
import Footer from "../../widgets/Footer";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { useAuth } from "../../../AuthContext";
import { Link } from "react-router-dom";
import { remove, total } from "../../../redux/cartSlice";
import CheckOut from "../../partials/checkout/CheckOut";

const Cart = () => {
  const dispatch = useDispatch();
  const { walletBalance, usdPrice } = useAuth();
  const [checkBoxField, setCheckBoxField] = useState("FSAD")
  const [show, setShow] = useState(false)
  const {
    item: products,
    totalamount,
    grandtotal,
  } = useSelector((state) => state.cart);

  const handleRemove = (productId) => {
    dispatch(remove(productId));
    dispatch(total());
  };
  const handleCheckBox = (e) => {
    const { value } = e.target;
    setCheckBoxField( value);
  };

  const handleShow=()=>{
    setShow(true)
  }
  useEffect(() => {
    scrollTop()
}, [])

const scrollTop = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

  return (
    <>
      <Navbar />
      <div className="p60">
        <div className="container">
          <div className="d-flex pb-3">
            <span>Your Shopping Cart</span>
            <Link className="ml-auto color1" to="/assets-library">
              Continue Shopping <i className="bi bi-chevron-right"></i>
            </Link>
          </div>
          <hr />

          <div className="row mt-5">
            <div className="col-md-8">
              <table className="table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Required FSADUSD Coins</th>
                    <th>Total Price (FSAD)</th>
                    {/* <th>Total Price (USD)</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((list) => {
                    return (
                      <tr>
                        <td>{list.title}</td>
                        <td>{list.quantity}</td>
                        <td>{list.price}</td>
                        <td>
                          {walletBalance > list.quantity * list.price
                            ? 0
                            : list.quantity * list.price - walletBalance}
                        </td>
                        <td>{list.quantity * list.price}</td>
                        {/* <td> $ {list.quantity * list.price * usdPrice}</td> */}
                        <td>
                          {" "}
                          <button
                            title="Delete"
                            className="btn btn-primary"
                            onClick={() => handleRemove(list.id)}
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <div className="big_box bg_blar p-md-4">
                <h6>Order Summary</h6>
                {/* <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      checked={checkBoxField === "FSAD"}
                      onChange={handleCheckBox}
                      value="FSAD"
                      name="select_coin"
                      type="radio"
                      className="form-check-input me-2"
                    />
                    FSAD
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      checked={checkBoxField === "USD"}
                      onChange={handleCheckBox}
                      value="USD"
                      name="select_coin"
                      type="radio"
                      className="form-check-input me-2"
                    />
                    USD
                  </label>
                </div> */}
                <div className="d-flex mb-2">
                  Item total{" "}
                  <span className="ml-auto text-end">{checkBoxField==="FSAD"? `${totalamount} (FSAD)`:`$ ${totalamount*usdPrice}`}</span>
                </div>
                <div className="d-flex mb-4">
                  Total{" "}
                  <span className="ml-auto text-end">{checkBoxField==="FSAD"? `${grandtotal} (FSAD)`:`$ ${grandtotal*usdPrice}`} </span>
                </div>
                {/* <a
                  className="paypal_btn d-flex align-itms-center justify-content-center w100"
                  href="#"
                >
                  <img src="/assets/img/paypal.png" alt="PayPal" /> Check out
                </a> */}
                {/* <div className="m-2 text-center">or</div> */}
                {products.length>0 &&  <button className="btn w100" onClick={handleShow} >
                  {" "}
                  Check out
                </button>}
               
                <p className="m-0 text-center mt-3">
                  Pay without a PayPal account
                </p>
              </div>
            </div>
          </div>
        </div>
        <CheckOut show={show} setShow={setShow}/>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
