import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

const Teamup = () => {
  const location = useLocation();
  const aboutUsRef = useRef(null);
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#Tokenomics":
        scrollToRef(aboutUsRef);
        break;
      //   case "#products":
      //     scrollToRef(productsRef);
      //     break;
      //   case "#pricing":
      //     scrollToRef(pricingRef);
      //     break;
      //   case "#contact":
      //     scrollToRef(contactUsRef);
      //     break;
      default:
        break;
    }
  }, [location.hash]);
  return (
    <div
      className=" p60 text-center "
      useRef={aboutUsRef}
      data-scroll-index="4"
    >
      <div className="container">
        <h2 className=" mb-3 mb-md-4 hadding ">
          {" "}
          Team up with us to <span>introduce the system</span>{" "}
        </h2>
        <div className="buy_box bg_blar">
          <div className="row justify-content-cente" id="counter">
            <div className="col-md-4  mb-4 mb-md-0">
              <img
                src="/assets/img/deployed-assets.png"
                alt="deployed-assets"
                className=" ani2"
              />
              <h4 className="mt-2">
                <span className="counter-value" data-count="0">
                  0 sdfsdf
                </span>
              </h4>
              <p className="mb-0">Deployed Assets</p>
            </div>

            <div className="col-md-4  mb-4 mb-md-0">
              <img
                src="/assets/img/d1.png"
                alt="/assets/img/d1.png"
                className=" ani2"
              />
              <h4 className="mt-2 ">
                ${" "}
                <span className="counter-value" data-count="10000000000">
                  0 sdfsdf
                </span>
              </h4>
              <p className="mb-0">Deployed Assets Total Value</p>
            </div>
            <div className="col-md-4  mb-4 mb-md-0">
              <img src="/assets/img/d2.png" alt="" className=" ani2" />
              <h4 className="mt-2">
                <span className="counter-value" data-count="10000">
                  0 sdfsdf
                </span>
              </h4>
              <p className="mb-0">Total FSADUSD Deposit</p>
            </div>
          </div>
        </div>

        <p className="text-center  mt-4">
          FSADUSD reserves of historical assets are held in the management and
          custody of leading financial institutions
        </p>
      </div>
    </div>
  );
};

export default Teamup;
