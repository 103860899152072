import { getWalletBalance } from "../Components/constant/api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWalletBalance: builder.mutation({
      query: (post) => ({
        url: getWalletBalance,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetUserWalletBalanceMutation} = userApi;