import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

const RoadMap = () => {
  const location = useLocation();
  const aboutUsRef = useRef(null);
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#Roadmap":
        scrollToRef(aboutUsRef);
        break;

      default:
        break;
    }
  }, [location.hash]);
  return (
    <>
      <div
        class="road_map p60 "
        ref={aboutUsRef}
        id="Roadmap"
        data-scroll-index="5"
      >
        <div class="container">
          <h2 class="text-center mb-md-5 hadding">
            Road<span>map</span>
          </h2>
          <div class="ova-road-map-v2">
            <div class="road-map-wrapper">
              <div class="timeline-row">
                <div class="timeline-items">
                  <div class="item">
                    <div class="timeline-item ">
                      <div class="timeline-date ">
                        <span class="date">Phase 1</span>
                      </div>
                      <div class="timeline-info animated ova-move-up ">
                        <h3 class="timeline-title">
                          Conceptualization and Research
                        </h3>
                        <ul class="timeline-content">
                          <li>
                            Development of the FSADUSD Stablecoin concept.
                          </li>
                          <li>Market research and feasibility studies.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="timeline-item ">
                      <div class="timeline-date ">
                        <span class="date ">Phase 2</span>
                      </div>
                      <div class="timeline-info animated ova-move-up ">
                        <h3 class="timeline-title  ">Technical Development </h3>
                        <ul class="timeline-content  ">
                          <li>
                            Implementation of the FSADUSD Stablecoin on the BNB
                            Network Chain.{" "}
                          </li>
                          <li>
                            Testing and optimization of blockchain
                            infrastructure.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-row">
                <div class="timeline-items">
                  <div class="item">
                    <div class="timeline-item ">
                      <div class="timeline-date ">
                        <span class="date ">
                          <span class="">Phase 3</span>
                        </span>
                      </div>
                      <div class="timeline-info animated ova-move-up ">
                        <h3 class="timeline-title ">Launch and Adoption</h3>
                        <ul class="timeline-content ">
                          <li>Public launch of FSADUSD Stablecoin.</li>
                          <li>Marketing and outreach to promote adoption.</li>
                          <li>
                            {" "}
                            Integration with exchanges and decentralized
                            applications.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="timeline-item timeline-not-done">
                      <div class="timeline-date ">
                        <span class="date">Phase 4</span>
                      </div>
                      <div class="timeline-info animated ova-move-up ">
                        <h3 class="timeline-title">Expansion and Impact </h3>
                        <ul class="timeline-content">
                          <li>
                            Continued development and enhancement of the FSADUSD
                            ecosystem.{" "}
                          </li>
                          <li>Expansion of use cases and partnerships. </li>
                          <li>
                            Ongoing commitment to social responsibility through
                            charitable initiatives.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadMap;
