import { addPayemntRequest, getCryptoCurrencyApi, getCryptoCurrencyPriceApi, getCurrencyPriceApi, getPaymentCountryApi, getPaymentMethod } from "../Components/constant/api";
import { myApi } from "./api";

export const paymentApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyPrice: builder.query({
      query: () => ({
        url: getCurrencyPriceApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getCryptoCurrencyPrice: builder.query({
      query: () => ({
        url: getCryptoCurrencyPriceApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getCryptoCurrency: builder.query({
      query: () => ({
        url: getCryptoCurrencyApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getPaymentCountry: builder.query({
        query: () => ({
          url: getPaymentCountryApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
      }),
      getPaymentMethod: builder.mutation({
        query: (post) => ({
          url: getPaymentMethod,
          method: "POST",
          body:post
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
      }),
      addPaymentRequest: builder.mutation({
        query: (post) => ({
          url: addPayemntRequest,
          method: "POST",
          body:post
        }),
      }),
    
  }),
});

export const {useGetCurrencyPriceQuery,useGetPaymentCountryQuery,useGetPaymentMethodMutation,useAddPaymentRequestMutation,useGetCryptoCurrencyPriceQuery,useGetCryptoCurrencyQuery} = paymentApi;