import React, { useState, useEffect } from "react";
// import blurImage from "../../img/blur.jpg"
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useGetCategoryQuery } from "../../redux/categoryApi";
import { useGetProductByCategoryMutation } from "../../redux/productApi";
import { add, total } from '../../redux/cartSlice';
import ConnectWalletModal from "../partials/connectWallet/ConnectWalletModal";
import QuickViewModal from "../partials/quickview/QuickViewModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SpotlightCurrency = () => {
  const dispatch=useDispatch()
  const { item: products} = useSelector((state) => state.cart);
  const { walletBalance,authenticated } = useAuth();
  const { data } = useGetCategoryQuery();
  const [getProductByCategory, { data: productList }] =
    useGetProductByCategoryMutation();
  const [categoryId, setCategoryId] = useState("");
  const [show, setShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(Array(productList?.length).fill(1))

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setCategoryId(data[0].id);
    }
  }, [data]);

  useEffect(() => {
    if (categoryId) {
      getProductByCategory({ id: categoryId });
    }
  }, [categoryId]);

  const handleChange = (e) => {
    const { value } = e.target;
    setCategoryId(value);
  };

  const handleShow = (record) => {
    setShow(true);
    setCurrentRecord(record);
  };

  const addtocart=(record,index)=>{
      if(!authenticated){
        handleShowModal()
      }
      else{
        if(quantity[index]==0){
          toast.dismiss()
          toast.error("Please add atleast one quantity")
          return false
        }
        const filterProduct=productList.filter((list=>{return list.id==record.id}))
        if(filterProduct[0].quantity==0){
          toast.dismiss()
          toast.error("Out of stock")
          return false
        }
        const filterProductAdd=products.filter((list=>{return list.id==record.id}))
        if(filterProductAdd.length>0 && filterProduct[0].quantity<parseFloat(quantity[index])+filterProductAdd[0]?.quantity){
          if(filterProduct[0].quantity-filterProductAdd[0].quantity==0){
            toast.dismiss()
            toast.error(`This product quantity is full`)
            return false
          }
          else{
            toast.dismiss()
            toast.error(`Only add ${filterProduct[0].quantity-filterProductAdd[0].quantity} quantities`)
            return false
          }
        }
      
        const image=JSON.parse(record?.image)
        const product={
          id:record?.id,
          title:record?.title,
          price:record.price_per_quantity,
          quantity:parseInt(quantity[index]),
          images:image && image[0]
      }
      dispatch(add(product));
      dispatch(total());
      toast.dismiss()
      toast.success("Add cart successfully")
      }
  }

  const handleQuantity=(e,i)=>{
    let newFormValues = [...quantity];
    newFormValues[i] = e.target.value;
    setQuantity(newFormValues)
  }

  return (
    <div className=" p60 text-center  bg_blar" data-scroll-index="6">
      <div className="container">
        <h2 className=" mb-2  hadding ">
          {" "}
          IN THE <span>SPOTLIGHT NOW</span>{" "}
        </h2>
        <p className="text-center  mb-4">
          The best choice of the historical assets to invest in{" "}
        </p>
        <div className="mb-4">
          <select
            className="form-control w-25"
            value={categoryId}
            onChange={handleChange}
          >
            {data?.map((list) => {
              return (
                <option value={list.id} key={list.is}>
                  {list.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="row">
          {productList?.slice(0, 4).map((list,index) => {
            const image=JSON.parse(list.image)
            return (
              <div className="col-md-4 mb-4" key={list.id}>
                <div className="bg_blar hisory_box">
                  <img src={image && image[0]} alt="blur" className="img-fluid" />
                  <div className="p-3">
                    <h5 className="text-left">{list.title}</h5>
                    <div className="row text-center mb-3 mt-3">
                      <div className="col-6">
                        Product No.: {list.product_no}
                      </div>
                      <div className="col-6">
                        Quantity: {list.total_quantity} {list.measuring_unit}
                      </div>
                    </div>
                    <h6 className=" ">FSADUSD DESIGNATED COINS</h6>
                    <h4>{list.total_quantity * list.price_per_quantity}</h4>
                    <h6 className=" mt-3">YOUR FSADUSD COINS AVAILABLE</h6>
                    <h4 className="text-danger">{quantity[index]*list.price_per_quantity}</h4>
                    <div className="row  mt-3 align-items-center">
                      <div className="col-6 pr-0 text-left">
                        Required FSADUSD Coins{" "}
                      </div>
                      <div className="col-6">
                  
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter quantity"
                          onChange={(e)=>{handleQuantity(e,index)}}
                          value={quantity[index]}
                          min={1}
                          max={list.quantity}
                          // disabled={!authenticated}
                        />
                      </div>
                    </div>
                    {/* <div className="row  mt-2 align-items-center">
                      <div className="col-6 pr-0 text-left">
                        Total Price USDT
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control"
                          placeholder="Enter USDT"
                          type="number"
                          disabled={!authenticated}
                        />
                      </div>
                    </div> */}
                    <div className="row  mt-3">
                      <div className="col-6 ">
                        <button className="btn d-block w-100" onClick={()=>{
                          addtocart(list,index)
                        }}>
                          Add to Cart
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn d-block w-100"
                          onClick={() => {
                            handleShow(list);
                          }}
                        >
                          Quick View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Link
        to={`/assets-library/${categoryId}`}
        className="btn mt-3 mt-md-4"
        href="assets-library.html"
      >
        Browse Our Assets Library
      </Link>
      <QuickViewModal
        currentRecord={currentRecord}
        setShow={setShow}
        show={show}
      />
       <ConnectWalletModal setShow={setShowModal} show={showModal} />
    </div>
  );
};

export default SpotlightCurrency;
