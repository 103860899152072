import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const location = useLocation();
  const aboutUsRef = useRef(null);
  useEffect(() => {
    console.log("hello");
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#about":
        scrollToRef(aboutUsRef);
        break;
      //   case "#products":
      //     scrollToRef(productsRef);
      //     break;
      //   case "#pricing":
      //     scrollToRef(pricingRef);
      //     break;
      //   case "#contact":
      //     scrollToRef(contactUsRef);
      //     break;
      default:
        break;
    }
  }, [location.hash]);
  return (
    <>
      <div id="scroll-to-top">
        <i className="bi bi-chevron-up"></i>
      </div>{" "}
      
      <section
        id="about"
        className="about-area-two p60 bg_blar"
        data-scroll-index="2"
      >
        <div
          ref={aboutUsRef}
          className="container custom-container-four text-center"
        >
          <h2 className="title hadding mb-4">
            ABOUT <span>US</span>
          </h2>
          <p>
            In an er characterized by rapid technological advancement and
            global push towards digitization, the financial world stands on the
            brink of a transformative shift towards a revolutionary model. This
            shift towards a quantum financial system will change global
            financial systems, including central banks and financial
            institutions, by utilizing blockchain technology and quantum
            computing for financial transactions. This, in turn, will enhance
            transaction speed, data analysis, security, and decentralization,
            creating an ecosystem for transactions that is scalable, secure,
            immutable, and transparent globally. It will rely on quantum
            computing, providing immense computational power capable of
            processing complex computations in a fraction of the time
            traditional computers take. This speed and efficiency could change
            the rules of the game for financial operations, from banking
            services to trading and investment.
          </p>
          <p>
            The aim of integrating quantum technology into finance is to address
            the most complex challenges of the current system in the industry,
            such as fraud, delays, and lack of transparency. Inherent features
            of quantum computing, such as superposition and entanglement, will
            help alleviate these problems by enabling the processing of multiple
            accounts simultaneously, improving speed and data security.
            Moreover, entanglement could provide secure communication channels,
            practically immune to any form of interception or fraud.
          </p>
          <p>
            At the heart of this seismic shift lies Committee FSAD369, founded
            by a group of individuals and investors with historical assets
            working with banks, financial institutions, and so forth. The
            committee consists of forward-thinking individuals dedicated to
            harnessing these untapped potentials of assets to defend human
            rights, promote economic justice, enhance sustainable development,
            empower communities, and collaborate for impactful change globally
            through equal opportunities, fair economic policies, growth
            strategies, legal initiatives, and cooperative efforts aimed at
            creating a more just, fair, and sustainable future for all.
          </p>
          <p>
            The FSAD369 Committee stands at the forefront, leading a bold
            endeavor to reshape the global economic landscape by introducing the
            FSADUSD stablecoin. Crafted based on the core principles of
            transparency, stability, and inclusivity, the FSADUSD Stablecoin
            aims to facilitate seamless exchange of value, thus driving economic
            prosperity to the farthest corners of the world.
          </p>
          <div className="row text-center mt-4 mt-md-5">
            <div className="col-md-3">
              <div className="about_box tf-step">
                <h4>01</h4>
                <h5>Quantum Financial System</h5>
                <p className="mb-0">
                  Integration of blockchain and quantum computing to enhance
                  transaction speed, data analysis, security, and
                  decentralization in finance.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="about_box tf-step">
                <h4>02</h4>
                <h5>Technological Benefits</h5>
                <p className="mb-0">
                  Quantum computing addresses fraud, delays, and transparency
                  issues, enabling faster processing and secure communications.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="about_box tf-step">
                <h4>03</h4>
                <h5>FSAD369 Members</h5>
                <p className="mb-0">
                  A group focused on leveraging these technologies to promote
                  human rights, economic justice, and sustainable development.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="about_box tf-step">
                <h4>04</h4>
                <h5>FSADUSD Stablecoin</h5>
                <p className="mb-0">
                  A stablecoin was introduced by the FSAD369 Committee to facilitate
                  transparent, stable, and inclusive global value exchange.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
