import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const KnowledgesLecture = () => {
  const location = useLocation();
  const aboutUsRef = useRef(null);
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#elite-sciences":
        scrollToRef(aboutUsRef);
        break;
      //   case "#products":
      //     scrollToRef(productsRef);
      //     break;
      //   case "#pricing":
      //     scrollToRef(pricingRef);
      //     break;
      //   case "#contact":
      //     scrollToRef(contactUsRef);
      //     break;
      default:
        break;
    }
  }, [location.hash]);
  return (
    <>
      <div id="scroll-to-top">
        <i className="bi bi-chevron-up"></i>
      </div>{" "}
      
      <section
        id="about"
        className="about-area-two p60 bg_blar"
        data-scroll-index="2"
      >
        <div
          ref={aboutUsRef}
          className="container custom-container-four text-center"
        >
          <h2 className="title hadding mb-4">
          Elite <span>Sciences</span>
          </h2>
          <p>
            In an era characterized by rapid technological advancement and
            global push towards digitization, the financial world stands on the
            brink of a transformative shift towards a revolutionary model. This
            shift towards a quantum financial system will change global
            financial systems, including central banks and financial
            institutions, by utilizing blockchain technology and quantum
            computing for financial transactions. This, in turn, will enhance
            transaction speed, data analysis, security, and decentralization,
            creating an ecosystem for transactions that is scalable, secure,
            immutable, and transparent globally. It will rely on quantum
            computing, providing immense computational power capable of
            processing complex computations in a fraction of the time
            traditional computers take. This speed and efficiency could change
            the rules of the game for financial operations, from banking
            services to trading and investment.
          </p>
          <p>
            The aim of integrating quantum technology into finance is to address
            the most complex challenges of the current system in the industry,
            such as fraud, delays, and lack of transparency. Inherent features
            of quantum computing, such as superposition and entanglement, will
            help alleviate these problems by enabling the processing of multiple
            accounts simultaneously, improving speed and data security.
            Moreover, entanglement could provide secure communication channels,
            practically immune to any form of interception or fraud.
          </p>
          <p>
            At the heart of this seismic shift lies Committee FSAD369, founded
            by a group of individuals and investors with historical assets
            working with banks, financial institutions, and so forth. The
            committee consists of forward-thinking individuals dedicated to
            harnessing these untapped potentials of assets to defend human
            rights, promote economic justice, enhance sustainable development,
            empower communities, and collaborate for impactful change globally
            through equal opportunities, fair economic policies, growth
            strategies, legal initiatives, and cooperative efforts aimed at
            creating a more just, fair, and sustainable future for all.
          </p>
          <p>
            The FSAD369 Committee stands at the forefront, leading a bold
            endeavor to reshape the global economic landscape by introducing the
            FSADUSD stablecoin. Crafted based on the core principles of
            transparency, stability, and inclusivity, the FSADUSD Stablecoin
            aims to facilitate seamless exchange of value, thus driving economic
            prosperity to the farthest corners of the world.
          </p>
        </div>
      </section>
    </>
  );
};

export default KnowledgesLecture;
