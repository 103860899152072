import React from 'react';

const Counter = ({ number }) => {
  // Convert the number to a string and pad it with leading zeros if needed
  const paddedNumber = number.toString().padStart(6, '0');
  
  return (
    <div className="counter">
      {paddedNumber.split('').map((digit, index) => (
        <div key={index} className="digit">
          <div className={`tape tape-${Math.pow(10, 3 - index)}`}>
        
              <div  className="number">
              {digit}
              </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Counter;
