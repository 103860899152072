import { getCategoryApi } from "../Components/constant/api";
import { myApi } from "./api";

export const categoryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => ({
        url: getCategoryApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetCategoryQuery} = categoryApi;