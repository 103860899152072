import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { add, total } from '../../../redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from "../../../AuthContext";
import ConnectWalletModal from "../connectWallet/ConnectWalletModal";
import { toast } from "react-toastify";

function QuickViewModal(props) {
  const { item: products} = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const {authenticated } = useAuth();
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    props.setShow(false);
  };
  const image = props?.currentRecord?.image
    ? JSON.parse(props?.currentRecord?.image)
    : [];

  const increment = () => {
    if (!(quantity >= props.currentRecord.max_quantity)) {
      setQuantity(quantity + 1);
    }
  };

  const decrement = () => {
    if (quantity - 1 >= 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAdd = () => {
    if(!authenticated){
      handleShowModal()
      handleClose()
    }
    else{
      const filterProduct=products.filter((list=>{return list.id==props?.currentRecord?.id}))
      if(filterProduct.length>0 && filterProduct[0].quantity>=quantity){
        toast.dismiss()
        toast.error("This product already exist")
        return false
      }
      const product={
          id:props?.currentRecord?.id,
          title:props?.currentRecord?.title,
          price:quantity*props.currentRecord.price_per_quantity,
          quantity:quantity,
          images:image && image[0]
      }
      dispatch(add(product));
      dispatch(total());
    }
};

  return (
    <div>
      {" "}
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 class="mb-3 mb-md-4">{props?.currentRecord?.title}</h4>
          <div class="row">
            <div class="col-md-6">
              <img src={image && image[0]} alt="blur" class="img-fluid" />
            </div>
            <div class="col-md-6">
              <div
                dangerouslySetInnerHTML={{
                  __html: props?.currentRecord?.description,
                }}
              ></div>

              <div class="row">
                {/* <div class="col-md-6 mb-4">
                  <select name="os0" size="1" class="form-control">
                    <option selected="" value="Small">
                      Small
                    </option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="X-Large">X-Large</option>
                  </select>
                </div> */}
                <div class="col-md-6 mb-4">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <button
                        class="btn"
                        data-dir="down"
                        title="Spinner Down"
                        type="button"
                        onClick={decrement}
                      >
                        −
                      </button>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      id="quantity"
                      name="quantity"
                      value={quantity}
                      title="Quantity"
                    />
                    <span class="input-group-append">
                      <button
                        class="btn"
                        data-dir="up"
                        title="Spinner Up"
                        type="button"
                        onClick={increment}
                      >
                        +
                      </button>
                    </span>
                  </div>{" "}
                </div>
                <div className="col-md-6 ms-2">
                  <button className="btn" onClick={()=>{handleAdd()}}>ADD TO CART</button>
                </div>
              </div>

              <p class="h5">Total: {quantity*props.currentRecord.price_per_quantity} <span>(FSAD)</span></p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ConnectWalletModal setShow={setShowModal} show={showModal} />
    </div>
  );
}

export default QuickViewModal;
