import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <section className="contact text-center footer" id="contact">
        <div className="container">
          <img
            src="/assets/img/logo.png"
            alt="header-Logo"
            className="logo mb-3"
          />

          <div className="footer_nav mt-3 mt-md-4">
            <Link to="#about" className="" data-scroll-nav="2">
              About Us
            </Link>
            <Link to="/Members" className="" data-scroll-nav="2">
              Members
            </Link>
            <Link to="#Tokenomics" className="" data-scroll-nav="3">
              Tokenomics
            </Link>
            <Link to="/assets-library" className="">
              Assets Library
            </Link>
            <Link to="/#elite-sciences" className="" data-scroll-nav="5">
            Elite Sciences
            </Link>
            <Link to="/#Roadmap" className="" data-scroll-nav="5">
              Roadmap
            </Link>
            <Link
              to="/#Contact"
              className=""
              href="#contact"
              data-scroll-nav="7"
            >
              Contact Us
            </Link>
            <Link to="/disclimer" className="">
              Disclaimer
            </Link>
          </div>

          <div className="footer_nav mt-2 mt-md-4">
            <Link to="/historical-assets" className="d-inline-block mt-3">
              List of FSAD369 HISTORICAL ASSETS
            </Link>
          </div>

          <div
            className="social-links mt-5 wow fadeInDown animated"
            data-wow-delay="0.1s"
          >
            <ul>
              <li>
                <Link target="_blank" to="/">
                  <i className="fa fa-telegram"></i>
                  <FaTelegramPlane style={{ width: "30px", height: "30px" }} />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="/">
                  <i className="fa fa-facebook"></i>
                  <FaFacebookF style={{ width: "30px", height: "30px" }} />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="/">
                  {" "}
                  <i className="fa fa-twitter"></i>
                  <FaXTwitter style={{ width: "30px", height: "30px" }} />
                </Link>
              </li>
              <li>
                <Link target="_blank" href="#">
                  <i className="fa fa-linkedin"></i>
                  <FaLinkedinIn style={{ width: "30px", height: "30px" }} />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="copyright">
          &copy; 2024 FSAD369. All Rights Reserved
        </div>
      </section>
    </>
  );
};

export default Footer;
