import React,{useEffect} from "react";
import { useGetMemberQuery } from "../../../redux/memberApi";
import Footer from "../../widgets/Footer";

const Members = () => {
  const { data } = useGetMemberQuery();
  useEffect(() => {
    scrollTop()
}, [])

const scrollTop = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

  return (
    <div>
      <div className="in_top bg_blar"></div>

      <div className="p60 text-center">
        <div className="container">
          <h2 className="mb-2 mb-md-4 hadding">Leadership and Members</h2>
          <div className="row">
            {data?.map((list) => {
              return (
                <div class="col-lg-3 mb-4 col-md-4  col-sm-2">
                  <div class="team_box tf-step">
                    <img
                      src={list.image}
                      alt="Dr. Firas dwikat"
                      className="team_img mb-3"
                    />
                    <h6 className="mb-0">{list.name}</h6>
                    <p class="mb-0">{list.position} </p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div class="row">
            <div class="col-lg-3 mb-4 col-md-4  col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/dr_firas_dwikat.jpg"  alt="Dr. Firas dwikat" className="team_img mb-3"/>
               <h6 className="mb-0">Dr. Firas Dwikat</h6>
               <p class="mb-0">Chairmain </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mbgm.jpg"  alt="Mazen Bitar General Manager" className="team_img mb-3"/>
               <h6 className="mb-0">Mazen Bitar</h6>
               <p class="mb-0">General Manager </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/afas.jpg"  alt="Asaad F. Abu Saleh" className="team_img mb-3"/>
               <h6 className="mb-0">Asaad F. Abu Saleh</h6>
               <p class="mb-0">Finance Director For Portfolio Affairs </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mad.jpg"  alt="Mohammed AL Dhaheri" className="team_img mb-3"/>
               <h6 className="mb-0">Mohammed AL Dhaheri</h6>
               <p class="mb-0">O&T Manager </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/saad_a.jpg"  alt="Saad A Abu-Odeh" className="team_img mb-3"/>
               <h6 className="mb-0">Saad A Abu-Odeh</h6>
               <p class="mb-0">Projects Manager </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/aez.jpg"  alt="Adv. Eyal Zeller" className="team_img mb-3"/>
               <h6 className="mb-0">Adv. Eyal Zeller</h6>
               <p class="mb-0">Finaicail Legal Advisor </p>
               </div>
            </div>
<div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/bzf.jpg"  alt="Basma zaidi-FSAD " className="team_img mb-3"/>
               <h6 className="mb-0">Basma zaidi</h6>
               <p class="mb-0">Executive Secretary </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/saleh_nabih.jpg"  alt="Saleh Nabih" className="team_img mb-3"/>
               <h6 className="mb-0">Dr. Nabih Saleh</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/hisham_atieh.jpg"  alt="Hisham Atieh" className="team_img mb-3"/>
               <h6 className="mb-0">Hisham Atieh</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/ahmad_al_amin.jpg"  alt="Ahmad al Amin" className="team_img mb-3"/>
               <h6 className="mb-0">Ahmad Al-Haider</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/moshe_diamant.jpg"  alt="Moshe Diamant" className="team_img mb-3"/>
               <h6 className="mb-0">Moshe Diamant</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/sofia_avner.jpg"  alt="Sofia Avner" className="team_img mb-3"/>
               <h6 className="mb-0">Sofia Avner</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/fayez_alkhatib.jpg"  alt="Fayez Alkhatib" className="team_img mb-3"/>
               <h6 className="mb-0">Fayez Alkhatib</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/essa_aljaloudi.jpg"  alt="Essa Aljaloudi" className="team_img mb-3"/>
               <h6 className="mb-0">Essa Aljaloudi</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/marwan_almuhaisen.jpg"  alt="Marwan Almuhaisen" className="team_img mb-3"/>
               <h6 className="mb-0">Marwan Almuhaisen</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/ma_khuffash.jpg"  alt="Mohammed al khuffash" className="team_img mb-3"/>
               <h6 className="mb-0">Mohammed al khuffash</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/jehad_al_dahleh.jpg"  alt="Jehad Al Dahleh" className="team_img mb-3"/>
               <h6 className="mb-0">Jehad Al Dahleh </h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/aak.jpg"  alt="Abed Almutaleb Kalboneh" className="team_img mb-3"/>
               <h6 className="mb-0">Abed Almutaleb Kalboneh</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mohammed_amro.jpg"  alt="Mohammed Amro" className="team_img mb-3"/>
               <h6 className="mb-0">Mohammed Amro</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mahmoud_abuquta.jpg"  alt="Mahmoud Abuquta" className="team_img mb-3"/>
               <h6 className="mb-0">Mahmoud Abuquta</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mar.jpg"  alt="MOHAMMAD AL-RIYATI" className="team_img mb-3"/>
               <h6 className="mb-0">Mohammad Al-Riyati</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/ahmed_al_hamdeen.jpg"  alt="Ahmed Al Hamdeen" className="team_img mb-3"/>
               <h6 className="mb-0">Ahmed Al Hamdeen</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/nabil_belhadj.jpg"  alt="Nabil Belhadj" className="team_img mb-3"/>
               <h6 className="mb-0">Nabil Belhadj</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/barraq_jumaa.jpg"  alt="Barraq Jumaa" className="team_img mb-3"/>
               <h6 className="mb-0">Barraq Jumaa</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/chakib_mouhli.jpg"  alt="Chakib Mouhli" className="team_img mb-3"/>
               <h6 className="mb-0">Chakib Mouhli</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
 <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/bassel_othman.jpg"  alt="Bassel Othman" className="team_img mb-3"/>
               <h6 className="mb-0">Bassel Othman</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/dee.jpg"  alt="Dr. Ehab Elghobary" className="team_img mb-3"/>
               <h6 className="mb-0">Dr. Ehab Elghobary</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/yonatan_melol.jpg"  alt="Yonatan Melol" className="team_img mb-3"/>
               <h6 className="mb-0">Yonatan Melol</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mahmoud_cadan.jpg"  alt="Mahmoud Cadan" className="team_img mb-3"/>
               <h6 className="mb-0">Mahmoud Cadan</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/immk.jpg"  alt="Ismaeal Karajat" className="team_img mb-3"/>
               <h6 className="mb-0">Ismaeal Karajat</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/kwk.jpg"  alt="Khaled Khuffash" className="team_img mb-3"/>
               <h6 className="mb-0">Khaled Khuffash</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/kadmany_samih.jpg"  alt="Kadmany samih" className="team_img mb-3"/>
               <h6 className="mb-0">Kadmany Samih</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/ahmad_amin.jpg"  alt="Ahmad Amin" className="team_img mb-3"/>
               <h6 className="mb-0">Ahmad Amin</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/radwan_hayani.jpg"  alt="Radwan Hayani" className="team_img mb-3"/>
               <h6 className="mb-0">Radwan Hayani</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/ameer_dwaikat.jpg"  alt="Ameer Dwaikat" className="team_img mb-3"/>
               <h6 className="mb-0">Ameer Dwaikat</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mm.jpg"  alt="Moncef Miniaoui" className="team_img mb-3"/>
               <h6 className="mb-0">Moncef Miniaoui</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/abdalfah_kalbona.jpg"  alt="Abdalfah Kalbona" className="team_img mb-3"/>
               <h6 className="mb-0">Abdalfah Kalbona</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/nade_fares.jpg"  alt="Nader fares" className="team_img mb-3"/>
               <h6 className="mb-0">Nader Fares</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/salh.jpg"  alt="Saeed - Al-Hatimi" className="team_img mb-3"/>
               <h6 className="mb-0">Saeed Al Hatimi</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/shker_hamod.jpg"  alt="Shker hamod" className="team_img mb-3"/>
               <h6 className="mb-0">Shker Hamod</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/yasser_raafat.jpg"  alt="Yasser.  Abu Raafat" className="team_img mb-3"/>
               <h6 className="mb-0">Yasser.  Abu Raafat</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/rawwa_abu_krayem.jpg"  alt="Rawwa Abu Krayem" className="team_img mb-3"/>
               <h6 className="mb-0">Rawwa Abu Krayem</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/nawaf_al_khasawneh.jpg"  alt="Nawaf Al Khasawneh" className="team_img mb-3"/>
               <h6 className="mb-0">Nawaf Al Khasawneh</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/mathew_sabu.jpg"  alt=" Mathew Sabu" className="team_img mb-3"/>
               <h6 className="mb-0"> Mathew Sabu</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/gigel_dinica.jpg"  alt="Gigel Dinica" className="team_img mb-3"/>
               <h6 className="mb-0"> Gigel Dinica</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>

            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/xxxxxx.jpg"  alt="dwikat" className="team_img mb-3"/>
               <h6 className="mb-0">xxxxxx</h6>
               <p class="mb-0">Member </p>
               </div>
            </div> 
            <div class="col-lg-3 mb-4 col-md-4 col-sm-2">
              <div class="team_box tf-step">
               <img src="/assets/img/team/hameednkukhon.jpg"  alt="A. HameednKukhon" className="team_img mb-3"/>
               <h6 className="mb-0">A. Hameedn Kukhon</h6>
               <p class="mb-0">Member </p>
               </div>
            </div>      
        </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Members;
