import React, { useState,useEffect } from "react";
// Assuming you're using React Router for routing
import { Link } from "react-router-dom";
import { CiShoppingCart } from "react-icons/ci";
import { MdOutlinePerson } from "react-icons/md";
import ConnectWalletModal from "../partials/connectWallet/ConnectWalletModal";
import { useAuth } from "../../AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { removeAll } from "../../redux/cartSlice";
import { useGetUserWalletBalanceMutation } from "../../redux/userApi";
import { useGetCurrencyQuery } from "../../redux/currencyApi";

const Navbar = () => {
  const {data}=useGetCurrencyQuery()
  const [getUserWalletBalance]=useGetUserWalletBalanceMutation()
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.cart);
  const { authenticated, walletAddress,logout,setWalletBalance,walletAddressId,setUsdPrice } = useAuth();
  const [show, setShow] = useState(false);
  const [scrolltopdata, setscrolltopdata] = useState('');

  useEffect(() => {
    if(walletAddressId && authenticated){
      getUserWalletBalance({wallet_id:walletAddressId}).then((res)=>{
        setWalletBalance(res.data[0].balance)
   })
    }
  }, [walletAddressId])

  useEffect(() => {
    if(data){
      setUsdPrice(data[0]?.usd_price)
    }
  }, [data])
  
  

  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY < 15) {
            setscrolltopdata('');
        } else {
           
            setscrolltopdata('nav-scroll');
          
        }
    });

}, [])

const handleDisconnect=()=>{
  dispatch(removeAll())
  logout()
}

  return (
    <nav className={`navbar navbar-expand-lg ${scrolltopdata}`}>
      <div className="container-fluid" id="cart">
        <Link to="/#home" className="navbar-brand">
          <img src="/assets/img/logo.png" alt="header-Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
        >
          <span className="icon-bar">
            <i className="bi bi-list"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ml-auto line">
            <li className="nav-item">
              <Link
                to="/#home"
                className="nav-link"
                href="#home"
                data-scroll-nav="1"
              >
                Home
              </Link>
            </li>
           
            <li className="nav-item">
              <Link to="/#about" className="nav-link" data-scroll-nav="2">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Members" className="nav-link" data-scroll-nav="2">
              Members
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#Tokenomics" className="nav-link" data-scroll-nav="3">
                Tokenomics
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/assets-library"
                className="nav-link"
                data-scroll-nav="4"
              >
                Assests Library
              </Link>
            </li>
            <li className="nav-item ">
              <Link to="/#elite-sciences" className="nav-link" data-scroll-nav="5">
              Elite Sciences
              </Link>
            </li>
            <li className="nav-item ">
              <Link to="/#Roadmap" className="nav-link" data-scroll-nav="5">
                Roadmap
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/#Contact"
                className="nav-link"
                href="#Contact"
                data-scroll-nav="7"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <Link to="/cart" className="cart_btn ml-4 position-relative">
          <CiShoppingCart />

          <span className="position-absolute">{count}</span>
        </Link>
        {authenticated && <Link to="/profile" className="cart_btn ml-4 position-relative">
        <MdOutlinePerson />
        </Link>}
     
        
        {authenticated?<><button className=" ml-4 position-relative btn btn-primary" title={walletAddress}>{walletAddress &&  walletAddress.slice(0, 5) + "..." + walletAddress.slice(-7)}</button>  <button onClick={handleDisconnect} className=" ml-4 position-relative btn btn-primary">Disconnect</button></>:<button
          className=" ml-4 position-relative btn btn-primary"
          onClick={() => {
            handleShow();
          }}
        >
          Connect Wallet
        </button>}
      
      </div>
      <ConnectWalletModal setShow={setShow} show={show} />
    </nav>
  );
};

export default Navbar;
