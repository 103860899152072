import React from "react";
import Footer from "../widgets/Footer";

const HistoricalAssets = () => {
  return (
    <div>
      <div className="in_top bg_blar"></div>
      <div className="p60 text-center">
        <div className="container">
          <h2 className=" mb-2 mb-md-4 hadding ">
            {" "}
            LIST OF FSAD369 <span>HISTORICAL ASSETS </span>{" "}
          </h2>
          <div className="big_box  bg_blar p-md-4 pt-md-5">
            <div className="table-responsive ">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No. </th>
                    <th>Asset ID</th>
                    <th>Discerption</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Total Assest reserved value USD$</th>
                    <th>Total FSADUSD Deposit</th>
                    <th> Statue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>DD54HH22</td>
                    <td>XYZ</td>
                    <td>XYZ</td>
                    <td>20</td>
                    <td>2000</td>
                    <td>4000</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>DD54HH22</td>
                    <td>XYZ</td>
                    <td>XYZ</td>
                    <td>20</td>
                    <td>2000</td>
                    <td>4000</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>DD54HH22</td>
                    <td>XYZ</td>
                    <td>XYZ</td>
                    <td>20</td>
                    <td>2000</td>
                    <td>4000</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>DD54HH22</td>
                    <td>XYZ</td>
                    <td>XYZ</td>
                    <td>20</td>
                    <td>2000</td>
                    <td>4000</td>
                    <td>Pending</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ul className="pagination justify-content-end">
        <li className="page-item">
          <a className="page-link" href="#">
            <i className="bi bi-chevron-left"></i>
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            <i className="bi bi-chevron-right"></i>
          </a>
        </li>
      </ul>
      {/* Modal content */}
      <div className="modal fade" id="myModal">
        <Footer />
      </div>
    </div>
  );
};

export default HistoricalAssets;
