import React from "react";
const Mission = () => {
  return (
    <div className=" p60">
      <div className="container">
        <div className="row   align-items-center">
          <div className="col-lg-6  mb-md-4">
            <div className="text-center position-relative ani">
              <img
                className="img-fluid launch_img"
                src="/assets/img/launch.png"
                alt="launch"
              />
              <div className="coin2">
                <img className="" src="/assets/img/coin2.png" alt="launch" />
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="">
              <h3 className="mb-2 hadding2 ">
                Vision
                <span>Statement</span>
              </h3>

              <p>
                To revolutionize the global financial ecosystem by pioneering
                the integration of quantum computing and blockchain technology,
                creating a transparent, secure, and decentralized financial
                landscape that promotes economic justice, defends human rights,
                and fosters sustainable development worldwide.
              </p>

              <h3 className="mb-2 hadding2 mt-4 ">
                Mission
                <span>Statement</span>
              </h3>
              <p>
                Our mission is to lead the transformative shift towards a
                quantum financial system through the FSAD369 Committee. By
                leveraging the immense computational power of quantum technology
                and the immutable security of blockchain, we aim to address the
                most complex challenges in the financial industry, enhance
                transaction speed, improve data security, and promote
                transparency. We are committed to empowering communities,
                promoting fair economic policies, and creating equal
                opportunities for all. Through the introduction of the FSADUSD
                Stablecoin, we strive to facilitate seamless value exchange,
                driving economic prosperity and fostering impactful global
                change
              </p>

              <a href="#" className="btn pl-4 pr-4" data-scroll-nav="6">
                Buy Now
              </a>
            </div>
          </div>
           
        </div>
        <div className="row mt-4 mt-md-5">
        <div className="col-md-10 m-auto">
        <div className="firas_dwikat_man bg_blar">
            <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
            <img className="firas-dwikat img-fluid" src="/assets/img/firas-dwikat.jpg" alt="firas dwikat" />
            </div>
            <div className="col-md-8">
              <p>"A new land, an organized life, a fair system, stability, and tranquility. Our world is witnessing a major shift in awareness, and humanity has begun to awaken. The world is eager for change and breaking the routine, which we observe through new beginnings, remarkable ideas, and the use of all technological capabilities to achieve the best means of facilitating life. We are part of this work that serves all of humanity, and we are grateful to God, who has made everything on this earth serve humanity.
              </p>
              <p>We strive to apply God's law, which preserves people's lives and ensures their safety, security, and dignity, free from slavery and injustice, and to confront the tools of control that dominate them, most notably money."
              </p>
              <h6 class="mb-0">Dr. Firas Dwikat</h6><p class="mb-0">Chairman </p>
            </div>
            </div>
            </div>
            </div>
      </div>
      </div>
    </div>
  );
};

export default Mission;
