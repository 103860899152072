import { getUserOrderApi } from "../Components/constant/api";
import { myApi } from "./api";

export const orderApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserOrder: builder.query({
      query: () => ({
        url: getUserOrderApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetUserOrderQuery} = orderApi;