import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Footer from "../../widgets/Footer";
import { IoCopyOutline } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { useGetUserOrderQuery } from "../../../redux/orderApi";
import Pagination from "../../constant/Pagination";
import OrderViewModal from "../../partials/order/OrderViewModal";

function Profile() {
  const navigate = useNavigate();
  const { data: orderHistory } = useGetUserOrderQuery();
  const { authenticated, walletAddress, walletBalance } = useAuth();
  const [copyStatus, setCopyStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currectRecord, setCurrectRecord] = useState({});
  const [showOrder, setShowOrder] = useState(false);

  useEffect(() => {
    if (!authenticated) {
      navigate("/");
    }
  }, [authenticated]);

  const copyToClipboard = () => {
    if (walletAddress) {
      setCopyStatus(true);
      toast.success("Wallet address copied successfully");
      copy(walletAddress);
      setTimeout(() => {
        setCopyStatus(false);
      }, 5000);
    }
  };

  const handleShowOrder = (record) => {
    setCurrectRecord(record);
    setShowOrder(true);
  };

  const totalPages = Math.ceil(
    orderHistory && orderHistory?.length / itemsPerPage
  );

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data for the current page
  const currentPageData =
    orderHistory && orderHistory?.slice(startIndex, endIndex);

  return (
    <div className="App">
      <div id="particles-js"></div>

      <div className="in_top bg_blar"></div>
      <div className="p60 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="bg_blar profile_box p-4 br15 position-relative overflow-hidden">
                <img
                  className="profile"
                  src="/assets/img/profile.jpg"
                  alt="profile"
                />
                <h5>Wallet Address:</h5>
                <p className="d-flex align-items-center">
                  {walletAddress &&
                    walletAddress.slice(0, 14) +
                      "..." +
                      walletAddress.slice(-10)}{" "}
                  {copyStatus ? (
                    <span className="ml-2 text-success">copied !</span>
                  ) : (
                    <span className="ml-2 copy-hover" onClick={copyToClipboard}>
                      <IoCopyOutline />
                    </span>
                  )}{" "}
                </p>

                <h5>
                  {walletBalance} <small>FSAAD</small>
                </h5>
              </div>
            </div>
            <div className="col-md-8">
              <div className="bg_blar p-4 br15 ">
                <h5 className="mb-4">Order Histary</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>SR. NO.</th>
                      <th>Order Id</th>

                      <th>Total</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData?.map((list, index) => {
                      return (
                        <tr key={list.id}>
                          <td>{index + 1}</td>
                          <td>{list.order_id}</td>
                          <td>{list.total_amount}</td>
                          <td>{list.status}</td>
                          <td>
                            <button
                              className="btn-primary"
                              onClick={() => {
                                handleShowOrder(list);
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderViewModal
        setShowOrder={setShowOrder}
        showOrder={showOrder}
        currentRecord={currectRecord}
      />
      <Footer />
    </div>
  );
}

export default Profile;
