export const paymentType=[
    {
        key:"FSAD WALLET",
        value:"FSAD"
    },
    {
        key:"FIAT",
        value:"FIAT"
    },
    {
        key:"CRYPTO",
        value:"CRYPTO"
    }
]

export const  CRYPTO="CRYPTO"
export const  FIAT="FIAT"
export const  FSAD="FSAD"