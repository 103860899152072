import React from "react";

const Partner = () => {
  return (
    <div className=" p60 patner bg_blar">
      <div className="container">
        <h2 className="text-center mb-2 hadding">
          FSAD369 <span>PARTNERS</span>
        </h2>
        <p className="text-center mb-4">
          These partnerships enhance FSAD369 reliability, security, and
          versatility for our global Customer
        </p>

        <div className="row">
          <div className="col-md-3 col-sm-4">
            <div className="choose-item tf-step">
              <h4>Leading Financial Institutions</h4>
              <p>
                Collaborating with top financial institutions for strong backing
                and robust collateral management.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="choose-item tf-step">
              <h4>Blockchain Innovators</h4>
              <p>
                Partnering with blockchain leaders to enhance security,
                efficiency, and scalability.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="choose-item tf-step">
              <h4>(DeFi) Platforms</h4>
              <p>
                Integrating with major DeFi platforms to expand FSADUSD's
                financial service offerings like lending and yield farming.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="choose-item tf-step">
              <h4>Auditing Firms</h4>
              <p>
                Working with reputable auditors for regular reviews of
                collateral and operations, ensuring transparencv.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
