import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function OrderViewModal(props) {
  const [products, setProducts] = useState([]);
  const handleClose = () => {
    props.setShowOrder(false);
  };

  useEffect(() => {
    if (props?.currentRecord.item) {
      setProducts(JSON.parse(props?.currentRecord?.item));
    }
  }, [props]);

  return (
    <div>
      <Modal
        show={props.showOrder}
        onHide={handleClose}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>Order View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="table-responsive">
            <div className="product-item">
              <table class="table ref14">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Quantity</th>
                    <th>Rate (FSAD)</th>
                    <th>Total (FSAD)</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((list) => {
                    let price = list.price;
                    return (
                      <tr>
                        <td>
                          <img
                            className="popup_pro_img "
                            src={list?.images}
                            alt=""
                          />
                        </td>

                        <td>
                          {" "}
                          <h6>{list.title}</h6>
                        </td>
                        <td>{list.quantity}</td>
                        <td>
                          <span className="me-3">{price} </span>
                        </td>
                        <td>{price * list.quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <div className="total m-3">
            <div className="d-flex justify-content-between">
              <p>Total</p>
              <p>{props?.currentRecord?.total_amount} (FSAD)</p>
            </div>
          
          </div>
          {props?.currentRecord?.message ?<div>
            <p><b>Message : </b> {props?.currentRecord?.message}</p>
          </div>:""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OrderViewModal;
