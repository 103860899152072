import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CRYPTO, FIAT, FSAD, paymentType } from "../../constant/enum";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    useAddPaymentRequestMutation,
  useGetCryptoCurrencyPriceQuery,
  useGetCryptoCurrencyQuery,
  useGetCurrencyPriceQuery,
  useGetPaymentCountryQuery,
  useGetPaymentMethodMutation,
} from "../../../redux/paymentApi";
import { InputValid } from "../../validations/InputValid";
import { useAuth } from "../../../AuthContext";
import { removeAll } from "../../../redux/cartSlice";
import Loader from "../../constant/Loader";

function CheckOut(props) {
  const { walletAddressId } = useAuth();
  const dispatch=useDispatch()
  const { grandtotal, item: product } = useSelector((state) => state.cart);
  const {data:cryptoCurrencyData}=useGetCryptoCurrencyQuery()
  const [getPaymentMethod, { data: paymentMethodData,isLoading }] =
    useGetPaymentMethodMutation();
  const { data: paymentCountry } = useGetPaymentCountryQuery();
  const { data: currencyPrice } = useGetCurrencyPriceQuery();
  const {data:cryptoCurrencyPrice}=useGetCryptoCurrencyPriceQuery()
  const [addPaymentRequest]=useAddPaymentRequestMutation()
  const [type, setType] = useState(FSAD);
  const [amount, setAmount] = useState(grandtotal);
  const [country, setCountry] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [currencyData, setCurrencyData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyErr, setCurrencyErr] = useState("");
  const [method, setMethod] = useState("");
  const [methodErr, setMethodErr] = useState("");
  const [chain, setChain] = useState("")
  const [chainErr, setChainErr] = useState("")
  const [disable, setDisable] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (currency && country) {
      getPaymentMethod({ country, currency });
    }
  }, [currency, country]);
  
  useEffect(() => {
   if(chain){
     const filtercurrency=cryptoCurrencyData.filter((list)=>{return list.code== chain})
      setCurrencyData(filtercurrency[0]?.tokens)
   }
  }, [chain])
  

  const handleClose = () => {
    setType(FSAD);
    setCurrencyData([]);
    setCurrency("");
    setCurrencyErr("");
    setCountryErr("");
    setCountry("");
    setMethod("");
    setMethodErr("");
    setChain("")
    setChainErr("")
    props.setShow(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      setType(value);
      setCountry("");
      setCurrencyData([]);
      setCurrency("");
      setMethod("");
      setChain("")
      setAmount(grandtotal);
    }
    if (name == "country") {
      setCountry(value);
      const check = InputValid(name, value);
      setCountryErr(check);
      const filterData = paymentCountry.filter((list) => {
        return list.country === value;
      });
      setCurrencyData(filterData[0].currencies);
    }

    if (name == "currency") {
      setCurrency(value);
      const check = InputValid(name, value);
      setCurrencyErr(check);
      if(type==FIAT){

        const filterData = currencyPrice.filter((list) => {
          return list.currency_name === value;
        });
        setAmount(grandtotal * parseFloat(filterData[0].price));
      }
      if(type==CRYPTO){

        const filterData = cryptoCurrencyPrice.filter((list) => {
          return list.currency_name === value;
        });
        setAmount(grandtotal * parseFloat(filterData[0].price));
      }
    }
    if (name === "method") {
      setMethod(value);
      const check = InputValid(name, value);
      setMethodErr(check);
    }
    if(name==="chain"){
      setChain(value)
      const check = InputValid(name, value);
      setChainErr(check);
    }
  };

  const handleSubmit = (e) => {
    setDisable(true)
    setTimeout(() => {
        setDisable(false)
    }, 7000);
    if (type == FIAT) {
      const checkCountry = InputValid("country", country);
      const checkCurrency = InputValid("currency", currency);
      const checkMethod = InputValid("method", method);
      if (checkCountry) {
        setCountryErr(checkCountry);
        return false;
      }
      if (checkCurrency) {
        setCurrencyErr(checkCurrency);
        return false;
      }
      if (checkMethod) {
        setMethodErr(checkMethod);
        return false;
      }
    }
    const data = {
      type: type,
      amount: amount,
      total_amount: grandtotal,
      country: country,
      currency: currency,
      payment_method: method,
      wallet_id: walletAddressId,
      item: JSON.stringify(product),
    };
    setLoader(true)
    addPaymentRequest(data).then((result) => {
        if (result.data.status) {
            const response=result.data.data
            if(type===FIAT || type === CRYPTO){
                 window.location.href=response.href
                 dispatch(removeAll())
            }
            else{
                toast.dismiss();
                toast.success(result.data.message);
                handleClose();
                setDisable(false);
                dispatch(removeAll())
            }
            setLoader(false)
        }
        else{
            toast.dismiss();
            toast.error(result.data.message);
            setDisable(false);
            setLoader(false)
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Check Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <lable>Payment Type</lable>
              <select
                value={type}
                onChange={handleChange}
                name="type"
                className="form-control"
              >
                {paymentType?.map((list) => {
                  return <option value={list.value}>{list.key}</option>;
                })}
              </select>
            </div>
            {type === FIAT && (
              <>
                <div className="mb-3">
                  <lable>Country</lable>
                  <select
                    value={country}
                    onChange={handleChange}
                    name="country"
                    className="form-control"
                  >
                    <option value="">Please Select Country</option>
                    {paymentCountry?.map((list) => {
                      return <option value={list.country}>{list.name}</option>;
                    })}
                  </select>
                  <span className="text-danger">{countryErr}</span>
                </div>
                <div className="mb-3">
                  <lable>Currency</lable>
                  <select
                    value={currency}
                    onChange={handleChange}
                    name="currency"
                    className="form-control"
                  >
                    <option value="">Please Select Currency</option>
                    {currencyData?.map((list) => {
                      return <option value={list}>{list}</option>;
                    })}
                  </select>
                  <span className="text-danger">{currencyErr}</span>
                </div>
                {isLoading?<Loader/>:""}
                <div className="mb-3">
                  <lable>Payment Method</lable>
                  <select
                    value={method}
                    onChange={handleChange}
                    name="method"
                    className="form-control"
                  >
                    <option value="">Please Select Method</option>
                    {paymentMethodData?.map((list) => {
                      return (
                        <option value={list.payment_method}>
                          {list.payment_method}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">{methodErr}</span>
                </div>
              </>
            )}
            {type===CRYPTO && (
              <>
               <div className="mb-3">
                  <lable>Chain</lable>
                  <select
                    value={chain}
                    onChange={handleChange}
                    name="chain"
                    className="form-control"
                  >
                    <option value="">Please Select Chain</option>
                    {cryptoCurrencyData?.map((list) => {
                      return <option value={list.code}>{list.code}</option>;
                    })}
                  </select>
                  <span className="text-danger">{chainErr}</span>
                </div>
                <div className="mb-3">
                  <lable>Currency</lable>
                  <select
                    value={currency}
                    onChange={handleChange}
                    name="currency"
                    className="form-control"
                  >
                    <option value="">Please Select Currency</option>
                    {currencyData?.map((list) => {
                      return <option value={list.code}>{list.code}</option>;
                    })}
                  </select>
                  <span className="text-danger">{currencyErr}</span>
                </div>
              </>
            )}

            <div className="mb-3">
              <lable>Amount</lable>
              <input value={amount} className="form-control" readOnly />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSubmit} disabled={disable || loader}>
            {loader?<Loader/>:""}Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CheckOut;
