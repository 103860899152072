import { getProductByCategoryApi } from "../Components/constant/api";
import { myApi } from "./api";

export const productApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductByCategory: builder.mutation({
      query: (post) => ({
        url: getProductByCategoryApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetProductByCategoryMutation} = productApi;