import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useGetCurrencyQuery } from "../../redux/currencyApi";

const Display = () => {
  const {data}=useGetCurrencyQuery()
  const location = useLocation();
  const contactUsRef = useRef(null);
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#Tokenomics":
        scrollToRef(contactUsRef);
        break;

      default:
        break;
    }
  }, [location.hash]);

  return (
    <div className="  bg_blar p60" data-scroll-index="3" id="Tokenomics">
      <div className="container" ref={contactUsRef}>
        <div className="tokenomics_box ">
          <h2 className=" mb-3 hadding text-center"> Tokenomics </h2>
          <h2 className="title2 text-center">1 FSADUSD = {data && data[0]?.usd_price} US Dollar</h2>
          <div className="row  ">
            <div className="col-md-10 m-auto">
              <h4 className=" lh33">
                FSADUSD Coin is fully backed by historical assets and built for
                rapid, global payments and 24/7 financial markets and is a
                regulated, digital currency that can always be redeemed 1:1 for
                US dollars.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
