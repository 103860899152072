import React from "react";
import { Link } from "react-router-dom";
import Footer from "../widgets/Footer";

const DisclaimerPage = () => {
  return (
    <div>
      <div className="in_top bg_blar"></div>

      <div className="p60 text-center">
        <div className="container">
          <h2 className="mb-2 mb-md-4 hadding">Disclaimer</h2>
          <div>
            <p className="text-left">
              The FSAD369 website and the FSADUSD Stablecoin project are
              presented by the FSAD369 Committee to provide information about
              our initiatives and vision for global economic prosperity through
              digital currency. The content on this website, including the
              Executive Summary, Introduction, Vision and Mission, and FSADUSD
              Stablecoin System Criteria, is for informational purposes only and
              does not constitute financial, investment, or legal advice.
            </p>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default DisclaimerPage;
